import React, { useState, useEffect } from 'react'
import Step0 from '../components/Auth/Step0';
import Step1 from '../components/Auth/Step1';
import Step2 from '../components/Auth/Step2';
import Step3 from '../components/Auth/Step3';
import SignIn from '../components/Auth/SignIn';
import Step2_2 from '../components/Auth/Step2_2';

import bgImg from '../assets/images/auth-bg.png';
import Navbar from '../components/Navbar';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from '../config/firebase';
import authService from '../auth/authService'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { serverTimestamp } from "firebase/firestore";
import mainApi from "../api/main";




export default function Auth() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [userId, setUserId] = useState('')
    const [userObj, setUserObj] = useState({})
    const [action, setAction] = useState('sign-in')
    const [emailMsg, setEmailMsg] = useState('')
    const [passMsg, setPassMsg] = useState('')
    const [rePassMsg, setRePassMsg] = useState('')
    const [step, setStep] = useState(0)
    const [teslaCred, setTeslaCred] = useState('');

    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')

    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');

    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')

    const [zip_code, setZipcode] = useState('')
    const [city, setCity] = useState('')


    const [beneficiary_name, setBeneficiaryName] = useState('')
    const [account_number, setAccountNumber] = useState('')
    const [bank_name, setBankName] = useState('')
    const [routing_number, setRoutingNumber] = useState('')
    console.log(typeof routingNumber);

    const changeFname = (e) => {
        setFname(e.target.value)
    }
    const changeLname = (e) => {
        setLname(e.target.value)
    }
    const changePassword = (e) => {
        setPassword(e.target.value)
    }
    const changeRePassword = (e) => {
        setRePassword(e.target.value)
    }
    const changeEmail = (e) => {
        setEmail(e.target.value)
        setEmailMsg('')
    }
    const changeAddress = (e) => {
        setAddress(e.target.value)
    }
    const changeZipcode = (e) => {
        setZipcode(e.target.value)
    }
    const changeCity = (e) => {
        setCity(e.target.value)
    }

    const changeBeneficiaryName = (e) => {
        setBeneficiaryName(e.target.value)
    }
    const changeAccountNumber = (e) => {
        setAccountNumber(e.target.value)
    }
    const changeBankName = (e) => {
        setBankName(e.target.value)
    }
    const changeRoutingNumber = (e) => {
        setRoutingNumber(e.target.value)
    }

    const goBackStep = () => {
        setStep(step - 1)
        console.log(step);
    }

    const data = {
        address,
        beneficiary_name,
        account_number,
        bank_name,
        routing_number,
        zip_code,
        city
    }

    // Functions
    const changes = {
        changeFname,
        changeLname,
        changePassword,
        changeRePassword,
        changeEmail,
        changeAddress,
        changeBeneficiaryName,
        changeAccountNumber,
        changeBankName,
        changeRoutingNumber,
        changeCity,
        changeZipcode,
        goBackStep,
    }


    const createUser = async (e) => {
        try {
            e.preventDefault()
            let response = await mainApi.updateUser(userObj?.uuid, {is_active: true, ...data});
            if(response?.status == 200) {
                navigate('/')
            }
          } catch (err) {
            console.log(err)
            authService.logout();
            setAction('sign-in')
          }

    }

    const nextStep = (e) => {
        e.preventDefault()
        if (step === 1) setStep(2)
        else if (step === 2) setStep(3)
    }

    const register = () => {
        return (
            <section className='h-full'>
                {/* {step === 0 && <Step0 />} */}
                {step === 1 && <Step1 onClick={nextStep} step={step} goBackStep={goBackStep} data={data} changes={changes} emailMsg={emailMsg} passMsg={passMsg} rePassMsg={rePassMsg} />}
                {step === 2 && <Step2 onClick={nextStep} step={step} data={data} changes={changes} goBackStep={goBackStep} />}
                {step === 3 && <Step2_2 onClick={createUser} step={step} goBackStep={goBackStep} />}
                {step === 4 && <Step3 onClick={nextStep} />}

                <div style={{ display: (step == 4 || step == 0) && 'none' }} className="flex flex-row items-center justify-center gap-1 mt-5">
                    <div className={step === 1 ? 'bg-[#178BE4] w-[8px] h-[8px] rounded' : 'bg-[#C6E2F8] w-[8px] h-[8px] rounded'} />
                    <div className={step === 2 ? 'bg-[#178BE4] w-[8px] h-[8px] rounded' : 'bg-[#C6E2F8] w-[8px] h-[8px] rounded'} />
                    <div className={step === 3 ? 'bg-[#178BE4] w-[8px] h-[8px] rounded' : 'bg-[#C6E2F8] w-[8px] h-[8px] rounded'} />
                </div>
            </section>
        )
    }

    async function teslaAuthentication (code) {
        
        let response = await mainApi.teslaAuthenticate({ code })
        
        //login
        if(response.data?.user.is_active) {
            authService.login(response?.data?.tokens);
            navigate('/');
        //Register
        }else {
            authService.login(response?.data?.tokens);
            setAction('register')
            setStep(1)
            setUserObj(response.data.user);
        }
      
    }

    useEffect(() => {  
        // test()
        // Upon return from tesla authentication page.
        if(searchParams.get('code')) {
            //send code to BE to authenticate & return access & refresh token
            teslaAuthentication(searchParams.get('code'))
        }

    }, [])

    async function test () {
        try {
            const response = await mainApi.updateUser('dfd',{hamada: 'fdsfd'})
        } catch (error) {
            console.log(error)
        }
      }
    

    return (
        <section className='flex flex-col md:flex-row h-screen overflow-auto'>
            <div className="absolute top-0 left-0 w-full z-[1000]">
                <Navbar userIdState={[userId, setUserId]} />
            </div>
            <img src={bgImg} alt='img' className='object-cover min-h-full md:w-[50%]' />
            <div className="w-full md:w-[50%] text-center py-[40px] pt-[100px] flex flex-col justify-between md:relative md:top-[0] md:left-[0] md:transform md:translate-x-[0] md:translate-y-[0] absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] ">
                {/* <div className="text-[24px] font-[800] flex items-center gap-5 justify-center ">
                    <button onClick={() => setAction('sign-in')} className={`${action === 'sign-in' ? 'text-white font-bold md:text-[#178BE4]' : 'text-[#C6E2F8] font-normal'}  text-[20px]`}>Sign In</button>
                    <span className='bg-[#1689E1] w-[1px] h-[40px]' />
                    <button onClick={() => setAction('register')} className={`${action !== 'sign-in' ? 'text-white font-bold md:text-[#178BE4]' : 'text-[#C6E2F8] font-normal'}  text-[20px]`}>Register</button>
                </div> */}
                <div className="mt-10 w-full sm:max-w-[432px] p-[16px] mx-auto h-full overflow-auto">
                    {
                    action === 'sign-in' ?
                        // <SignIn
                        //     userIdState={[userId, setUserId]}
                        //     stepState={[step, setStep]}
                        //     actionState={[action, setAction]}
                        //     teslaCredState={[teslaCred, setTeslaCred]}
                        // />
                        <Step0 />
                        :
                        register()
                    }
                </div>
            </div>
        </section>

    )
}
