import React, { useState } from 'react'
import Field from '../Field'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton } from '@mui/material';

export default function Step1({ onClick, step, data, changes, emailMsg, passMsg, rePassMsg, goBackStep }) {
    // const [showPassword, setShowPassword] = useState(false);
    return (
        <form onSubmit={onClick} className='flex flex-col gap-3 w-full mx-auto '>
            <div className="flex flex-row items-center gap-10">
                <div className="flex-[1] h-[1px] bg-[#178BE4]" />
                <span className='text-[#178BE4] font-bold'>Personal Information</span>
                <div className="flex-[1] h-[1px] bg-[#178BE4]" />
            </div>
            {/* <div className="flex flex-row gap-5 items-start">
                <Field id={'fname'} label={'First Name'} type={'text'} value={data.fname} onChange={changes.changeFname} />
                <Field id={'lname'} label={'Last Name'} type={'text'} value={data.lname} onChange={changes.changeLname} />
            </div> */}
            {/* <div className="flex flex-row gap-5 items-start">
                <div className="relative">
                    <Field id={'pass'} label={'Password'} type={showPassword ? "text" : "password"} value={data.password} onChange={changes.changePassword} msg={passMsg} />
                    <div className="absolute top-[28px] right-[10px]" onClick={() => setShowPassword(prev => !prev)}>
                        <IconButton size={'small'}>{!showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}</IconButton>
                    </div>
                </div>
                <div className="relative">
                    <Field id={'repass'} label={'Retype Password'} type={showPassword ? "text" : "password"} value={data.rePassword} onChange={changes.changeRePassword} msg={rePassMsg} />
                    <div className="absolute top-[28px] right-[10px]" onClick={() => setShowPassword(prev => !prev)}>
                        <IconButton size={'small'}>{!showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}</IconButton>
                    </div>
                </div>
            </div> */}
            {/* <Field id={'email'} label={'Email'} type={'email'} value={data.email} onChange={changes.changeEmail} msg={emailMsg} /> */}
            <Field id={'address'} label={'Address'} type={'text'} value={data.address} onChange={changes.changeAddress} />
            <div className="flex flex-row gap-5 items-start">
                <Field id={'zipcode'} label={'Zip Code'} type={'number'} value={data.zipcode} onChange={changes.changeZipcode} />
                <Field id={'city'} label={'City'} type={'text'} value={data.city} onChange={changes.changeCity} />
            </div>

            <div className='flex flex-row gap-5 items-start mt-10'>
                <button className='bg-[#EEEEEE] text-[#000] p-2 rounded font-bold w-full' onClick={goBackStep} type={'button'}>Back</button>
                <button className='bg-[#178BE4] p-2 rounded text-white font-bold w-full' type={'submit'}>Next</button>
            </div>
        </form>
    )
}
