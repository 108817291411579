import React, { useState } from 'react'
import leftArrow from '../assets/images/leftArrow.png'

export default function Slider() {
    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide < 2 ? prevSlide + 1 : 0));
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide > 0 ? prevSlide - 1 : 2));
    };

    const slideStyle = {
        transform: `translateX(-${currentSlide * 100}%)`,
    };

    return (
        <section id='services' className="slider-container w-full relative overflow-hidden">
            <div className="slider" style={slideStyle}>
                {/* <div className="slide slide1  ">
                    <div className="layout px-[45px] sm:px-[200px] h-[500px] flex flex-col gap-6 text-white items-center justify-center">
                        <h2 className='text-3xl sm:text-4xl font-bold'>Minimize <br /> Charging Costs </h2>
                        <p className='text-xl sm:text-2xl'>Intelligent, Automated LCFS Credit Redemption</p>
                    </div>
                </div> */}
                <div className="slide slide2 ">
                    <div className="layout px-[24px] sm:px-[200px] h-[500px] flex flex-col gap-6 text-white items-center justify-center">
                        <h2 className='text-2xl sm:text-4xl font-bold'>Boost your financial gains today! <br className='hidden sm:block' /> (~$150 000/year) </h2>
                        <p className='text-xl sm:text-2xl'>Based on an EV fleet mix of 50 EVs</p>
                    </div>
                </div>
                {/* <div className="slide slide3  ">
                    <div className="layout px-[45px] sm:px-[200px] h-[500px] flex flex-col gap-6 text-white items-center justify-center">
                        <h2 className='text-3xl sm:text-4xl font-bold'> Fast, Easy <br /> Redemption Process</h2>
                        <p className='text-xl sm:text-2xl'>Swift Integration with EV Chargers * average number of savings based on enterprise fleet of 100 Passenger EVs Appendix </p>
                    </div>
                </div> */}
            </div>

            {/*  // ============================== //
                //          sliders btn           // 
               // ============================== // */}

            {/* <button className='absolute top-[50%] bottom-[50%] left-[16px] sm:left-[70px]' onClick={prevSlide}>
                <img className='w-[24px]' src={leftArrow} alt="button" />
            </button>
            <button className='absolute top-[50%] bottom-[50%] right-[16px] sm:right-[70px]' onClick={nextSlide}>
                <img className='w-[24px] rotate-180' src={leftArrow} alt="button" />
            </button> */}
        </section>


    )
}
