import React, { createContext, useState } from 'react';
import useLoading from '../pages/hooks/useLoading';

export const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
    const { isLoading, startLoading, stopLoading } = useLoading();

  return (
    <LoadingContext.Provider value={{ isLoading, startLoading, stopLoading }}>
      {children}
    </LoadingContext.Provider>
  );
}