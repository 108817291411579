import React from 'react'

export default function Step0({onClick, step, data, changes}) {
   
    const teslaUrl = process.env.REACT_APP_TESLA_LOGIN_URL
    return (
        <div>
                <h1 style={{ fontSize:'1.3rem', marginBottom: '1rem', fontWeight: 'bold' }}>Welcome to CommuteSaver EV!</h1> 
            <h5>
                To authenticate with our platform, it’s mandatory to have a Tesla account. If you don’t have an account already we invite you to create one by visiting <a style={{color:'blue'}} href='https://tesla.com'>Tesla's official website</a>. Upon securing your Tesla account, you would be able to proceed with the registration on CommuteSaver EV.
            </h5>
            <button className='bg-[#178BE4] p-2 rounded text-white font-bold w-full' onClick={() => window.location.assign(teslaUrl)}>Sign In</button>
        </div>
    )
}
