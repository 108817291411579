import React from 'react'
import img from '../assets/images/supporters1.png'

export default function Supports() {
    return (
        <section id='supporters' className='py-[70px] bg-[#F2F2F2] '>
            <article className='max-w-[80rem] mx-auto'>
                <h2 className='text-[#656565] text-xl sm:text-3xl font-[400] text-center'>Supporters</h2>
                <img className='mx-auto w-[300px] sm:w-[auto]' src={img} alt="" />
            </article>
        </section>
    )
}
