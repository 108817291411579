
import { createBrowserRouter, RouterProvider} from "react-router-dom";
import { useContext, useEffect } from "react";
import { LoadingContext } from '../contexts/LoadingContext';
import LoadingSpinner from "./LoadingSpinner";
import { setupInterceptors } from "../api/client";
import Home from "../pages/Home";
import Auth from "../pages/Auth";
  
  
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/auth",
      element: <Auth />,
    },
  ]);
  
  function Main() {
  
    const { startLoading, stopLoading } = useContext(LoadingContext);
  
    useEffect(() => {
      setupInterceptors(startLoading, stopLoading);
    }, [startLoading, stopLoading]);
  
    return (
        <div className="App">
          <LoadingSpinner />
          <RouterProvider router={router} />
        </div>
    );
  }
  
  export default Main;
  