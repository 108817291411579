import React, { useState, useEffect } from 'react';
import  axios  from 'axios';

import AppBar from '@mui/material/AppBar';
import {
    Box,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    Container,
    Avatar,
    Button,
    Tooltip,
    MenuItem,

} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AdbIcon from '@mui/icons-material/Adb';

import userLogo from '../assets/images/user-logo.png'
import logo from '../assets/images/logo.png'
import whiteLogo from '../assets/images/white-logo.png'
import { signOut } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, db } from '../config/firebase';
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import authService from '../auth/authService';
import mainApi from "../api/main";

const pages = [
    { title: 'Services', path: '/#services' },
    { title: 'Supporters', path: '/#supporters' },
    { title: 'Contact', path: '/#contact' },
];

function Navbar({ userIdState }) {
    const navigate = useNavigate();
    let location = useLocation();
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [userId, setUserId] = userIdState

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };



    const handleLogout = () => {
        authService.logout();
        navigate('/auth')
    }


    return (
        <AppBar position="static" sx={{ bgcolor: 'transparent', boxShadow: 'none' }} >
            {location.pathname !== '/auth' && <div className="rounded-b-xl h-[20px] bg-[#199CFF]" />}
            <Container sx={{ maxWidth: '80rem', marginX: 'auto' }}>
                <Toolbar disableGutters sx={{ gap: '24px' }} >
                    {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
                    <Box sx={{ flexGrow: 1 }}>
                        <a href="/" className='inline-flex'>
                            <img className='w-[200px] sm:w-[250px]' src={location.pathname !== '/auth' ? logo : whiteLogo} alt="logo" />
                        </a>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            sx={{ color: { xs: 'white', sm: '#178be4' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page, i) => (
                                <MenuItem key={i} onClick={handleCloseNavMenu}>
                                    <a href={page.path}>{page.title}</a>
                                </MenuItem>
                            ))}
                            {!authService.isLoggedIn() && <MenuItem onClick={handleCloseNavMenu}>
                                <a href='/auth' >Sign In</a>
                            </MenuItem>}
                        </Menu>
                    </Box>

                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page, i) => (
                            <Button
                                href={page.path}
                                key={i}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: '#199CFF', display: 'block', textTransform: 'capitalize' }}
                            >
                                {page.title}
                            </Button>
                            
                        ))}
                        {!authService.isLoggedIn() &&
                            <>
                                <Button
                                    href='/auth'
                                    on 
                                    Click={handleCloseNavMenu}
                                    sx={{ my: 2, color: '#199CFF', display: 'block', textTransform: 'capitalize' }}
                                >
                                    Sign In
                                </Button>
                                {/* <Button
                                    href='/auth'
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: '#199CFF', display: 'block', textTransform: 'capitalize' }}
                                >
                                    Register Now
                                </Button> */}
                                
                            </>}
                    </Box>

                    {authService.isLoggedIn() &&
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }}>
                                    <div className='w-[20px] h-[20px] flex items-center justify-center'><img alt="Remy Sharp" src={userLogo} /></div>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >

                                <MenuItem disabled><span>Hello, {authService.getUser().userName}</span></MenuItem>
                                <MenuItem onClick={handleLogout}>
                                    <Typography color={'red'} textAlign="start">Logout</Typography>
                                </MenuItem>

                            </Menu>
                        </Box>

                    }
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default Navbar;