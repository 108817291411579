import { jwtDecode } from "jwt-decode";
// import { useNavigate } from 'react-router-dom';


const tokenKey = "token";
const refreshTokenKey = "refreshToken";

export async function login(tokens) {
  localStorage.setItem(tokenKey, tokens?.access?.token);
  localStorage.setItem(refreshTokenKey, tokens?.refresh?.token);

  // return jwt_decode(data?.access_token);
}

export function logout() {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(refreshTokenKey);
}

export function getUser() {
  if (localStorage.getItem(tokenKey)) return jwtDecode(localStorage.getItem(tokenKey));
  else return null;
}

export function isLoggedIn() {
  if (localStorage.getItem(tokenKey)) return true
  else return false;
}

export function getToken() {
  return localStorage.getItem(tokenKey);
}

export function getRefreshToken() {
  return localStorage.getItem(refreshTokenKey);
}






export default {
  login,
  logout,
  getUser,
  isLoggedIn,
  getToken,
  getRefreshToken
};
