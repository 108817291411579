import React from 'react'
import img1 from '../assets/images/img1.png'
import img2 from '../assets/images/img2.png'
import img3 from '../assets/images/img3.png'

export default function ProvenConcept() {
    return (
        <section id='services' className='p-[24px] max-w-[80rem] mx-auto'>
            <article className="shape w-[300px] sm:w-[350px] h-[250px] text-center px-[32px] py-[60px] border-4 border-[#199CFF] mt-[-100px] mx-auto">
                <h2 className='text-2xl sm:text-3xl font-bold mb-3 text-[#199CFF]'>CLAIM YOUR CASHBACK NOW</h2>
                <p>Register for free</p>
								<a href="/auth" className="font-bold text-[#199CFF]" >Here</a>
            </article>

            <article className='py-10'>
                <h2 className='text-[#199CFF] text-3xl sm:text-4xl text-center font-bold my-[60px]'>WE PROVIDE <br /> CASHBACK FOR</h2>
                <div className="flex flex-col sm:flex-row gap-10 justify-center text-center text-xl font-bold">
                    <div className="mx-auto flex flex-col items-center">
                        <div className="shape shape2 w-[150px] h-[150px] flex items-center justify-center">
                            <img className='w-[40%] mb-3' src={img3} alt="" />
                        </div>
                        <p className='font-[400] mt-2'>Corporate EV fleets</p>
                    </div>
                    <div className="mx-auto flex flex-col items-center">
                        <div className="shape2 shape w-[150px] h-[150px] flex items-center justify-center">
                            <img className='w-[40%] mb-3' src={img1} alt="" />
                        </div>
                        <p className='font-[400] mt-2'>EV Ridesharing drivers{<br/>} (such as Uber, Lyft etc.)</p>
                    </div>
                    <div className="mx-auto flex flex-col items-center">
                        <div className="shape shape2 w-[150px] h-[150px] flex items-center justify-center">
                            <img className='w-[40%] mb-3' src={img2} alt="" />
                        </div>
                        <p className='font-[400] mt-2'>Individual EV owners</p>
                    </div>

                </div>
            </article>

            <article className='grid grid-cols-1 sm:grid-cols-2 gap-4 my-[90px] text-white'>
                <div className="back_img-2 bg-[#199CFF] h-full flex items-center justify-center text-center p-[24px]">
                    <h2 className='text-4xl my-[100px] sm:text-5xl font-bold'>CommuteSaver EV</h2>
                </div>
                <div className="grid grid-rows-3 gap-4 text-[20px] font-[400]">
                    <p className='px-[32px] py-[60px] bg-[#199CFF]'>Register today to get cashback for your EV driving instantly</p>
                    <p className='px-[32px] py-[60px] bg-[#199CFF]'>We currently serve the following states: <br /> <br />

                        California, Oregon, Washington and Canada</p>
                    <p className='px-[32px] py-[60px] bg-[#199CFF]'>Keep yourself updated about our expansion into your state by contacting us today!</p>
                </div>
            </article>

            <article className='text-center text-[#656565] py-[100px]'>
                <h2 className='text-2xl sm:text-[32px] font-bold mb-10'>Does your firm own a corporate EV fleet? </h2>
                <p className='text-[18px] sm:text-[24px]'>Our customers receive ~25-30% of their charging costs back. <br /> Contact us today to discuss your possibilties.</p>
            </article>

        </section>
    )
}
