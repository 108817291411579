import React from 'react'

export default function Header() {
    return (
        <header className='hero h-[400px] sm:h-[100vh] rounded-xl'>
            <div className="px-[24px] layout h-full rounded-xl">
                <div className='max-w-[80rem] mx-auto flex flex-col h-full gap-3 items-center justify-center text-center'>
                    <h1 className='text-4xl sm:text-5xl font-bold'>CommuteSaver EV</h1>
                    <p className='text-xl'>Cashback for driving your EV</p>
                </div>
            </div>
        </header>
    )
}
