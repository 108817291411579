// import { create } from "apisauce";
// import auth from "../auth/authService";


// const apiClient = create({
//   // eslint-disable-next-line
//   baseURL: "http://localhost:3000",
// });

// // adding the token to the header of every request
// apiClient.addAsyncRequestTransform(async (request) => {
//   const authToken = await auth.getToken();
//   if (!authToken) return;
//   request.headers["Authorization"] = `Bearer ${authToken}`;
//   request.headers["Content-Type"] = 'application/json'
//   request.headers["Access-Control-Allow-Origin"] = '*'
// });


// export default apiClient;



import axios from 'axios';
import auth from "../auth/authService";
import { useContext } from 'react';
import { LoadingContext } from '../contexts/LoadingContext';

const baseURL = 'https://commutesaver.net/commutesaverev-api'

const axiosInstance = axios.create({
  baseURL: baseURL, // Replace with your API base URL
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setupInterceptors = (startLoading, stopLoading) => {

  axiosInstance.interceptors.request.use(
    (config) => {
      startLoading();

      const accessToken = auth.getToken();
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      stopLoading();

      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      stopLoading();

      return response;
    },
    async (error) => {
      stopLoading();

      const originalRequest = error.config;
      if (error.response.status === 500 && error.response.data.message == "jwt expired" && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = auth.getRefreshToken();
        try {
          const response = await axios.post(`${baseURL}/v1/auth/refreshtoken`, { token: refreshToken });
          auth.login(response.data?.tokens)
          axiosInstance.defaults.headers['Authorization'] = `Bearer ${response?.data?.tokens?.access?.token}`;
          originalRequest.headers['Authorization'] = `Bearer ${response?.data?.tokens?.access?.token}`;
          return axiosInstance(originalRequest);
        } catch (err) {
          console.log('Refresh token failed');
          // Handle token refresh failure, like redirecting to login
        }
      }
      return Promise.reject(error);
    }
  );
}

export default axiosInstance;
