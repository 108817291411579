// src/components/LoadingSpinner.js
import React, { useContext } from 'react';
import { LoadingContext } from '../contexts/LoadingContext';
import { ClipLoader, PulseLoader } from 'react-spinners';
import './LoadingSpinner.css'

const LoadingSpinner = () => {
  const { isLoading } = useContext(LoadingContext);

  return (
    <div className={`spinner-container ${isLoading ? 'show' : ''}`}>
      <PulseLoader color="#123abc" loading={isLoading} size={50} />
    </div>
  );
};

export default LoadingSpinner;


