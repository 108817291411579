import { LoadingProvider } from './contexts/LoadingContext';
import Main from "./components/Main";





function App() {

  return (
    <LoadingProvider>
     <Main />
    </LoadingProvider>
  );
}

export default App;
