import React, { useEffect, useState } from 'react'
import axios from "axios";

import { useNavigate, useSearchParams } from 'react-router-dom';
import Contact from "../components/Contact";
import Header from "../components/Header";
import Supports from "../components/Supports";
import ProvenConcept from "../components/ProvenConcept";
import Slider from "../components/Slider";
import Navbar from '../components/Navbar';
import authService from '../auth/authService';
// import useTeslaAuthentication from '../auth/useTeslaAuthentication';
//firebase
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../config/firebase';
import { doc, updateDoc } from "firebase/firestore";


export default function Home() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [userId, setUserId] = useState('')
    const navigate = useNavigate();

    const handleLogout = () => {
        signOut(auth).then(() => {
            setUserId()
            navigate("/");
            console.log("Signed out successfully")
        }).catch((error) => {
            // An error happened.
            console.log(error);
            alert(error.message)
        });
    }

    function teslaAuthentication (code, user) {
        
          axios
          .post('https://auth.tesla.com/oauth2/v3/token', {
              grant_type: "authorization_code",
              client_id: process.env.REACT_APP_CLIENT_ID,
              client_secret: process.env.REACT_APP_CLIENT_SECRET,
              audience: "https://fleet-api.prd.eu.vn.cloud.tesla.com",
              code,
              redirect_uri: "https://commutesaverev.com/auth"
          })
          .then((response) => {
              authService.login(response.data)
              updateDoc(doc(db, "users", user?.uid),
                {
                    teslaToken: response.data?.access_token,
                    teslaRefreshToken: response.data?.refresh_token
                });
              navigate("/")
          }).catch(error => {
              console.log(error);
            });
        
        }
    

    // useEffect(() => {
    //     onAuthStateChanged(auth, (user) => {
    //         if (user) {
    //             if(searchParams.get('code')) {
    //                 teslaAuthentication(searchParams.get('code'), user)
    //             //    useTeslaAuthentication(searchParams.get('code'))
    //             }else if (!authService.getTeslaToken()) handleLogout()
    //         }
    //     });
        
    // }, [])

    return (
        <div>
            <Navbar userIdState={[userId, setUserId]} />
            <Header />
            <ProvenConcept />
            <Slider />
            <Supports />
            <Contact />
        </div>
    )
}
