import React from 'react'
import Field from '../Field'

export default function Step2({ onClick, step, data, changes, goBackStep }) {

  return (
    <form onSubmit={onClick} className='flex flex-col gap-3 w-full mx-auto '>
      <div className="flex flex-row items-center gap-5">
        <div className="flex-[1] h-[1px] bg-[#178BE4]" />
        <span className='text-[#178BE4] font-bold max-w-[61%]'>Where do you prefer to receive your cashback?</span>
        <div className="flex-[1] h-[1px] bg-[#178BE4]" />
      </div>

      {/* ============== */}
      <Field id={'name'} label={'Full Beneficiary Name'} type={'name'} placeholder={'Full Beneficiary Name'} value={data.beneficiaryName} onChange={changes.changeBeneficiaryName} />
      <Field id={'routingNum'} label={'Routing Number'} type={'number'} placeholder={'Routing Number'} value={data.routingNumber} onChange={changes.changeRoutingNumber} />
      <Field id={'accNum'} label={'Account Number'} type={'number'} value={data.accountNumber} onChange={changes.changeAccountNumber} />
      <Field id={'bankName'} label={'Bank Name'} type={'text'} value={data.bankName} onChange={changes.changeBankName} />

      <div className='flex flex-row gap-5 items-start mt-10'>
        <button className='bg-[#EEEEEE] text-[#000] p-2 rounded font-bold w-full' onClick={goBackStep} type={'button'}>Back</button>
        <button className='bg-[#178BE4] p-2 rounded text-white font-bold w-full' type={'submit'}>Next</button>
      </div>
    </form>
  )
}
