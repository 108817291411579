import client from "./client";


const teslaAuthenticate = (data) => client.post("/v1/auth/teslaAuthenticate", data);
const updateUser = (userId, data) => client.patch(`/v1/users/${userId}`, data);


//Address
//const getStates = () => client.get(`/address/states/?pagination=false`);
//const getAreas = (stateId) => client.get(`/address/states/${stateId}/areas`);

//Entity
//const createEntity = (entityObj) => client.post("/entity/v1/entities/", entityObj);
//const entityLogin = (data) => client.post("/auth/v1/login/", data);


export default {
  teslaAuthenticate,
  updateUser
};
