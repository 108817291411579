import React, { useState } from 'react'

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function Step2_2({ onClick, goBackStep, step }) {
  const [value, setValue] = useState('disagree');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <section className='text-start text-[13px] text-[#178BE4]'>
      <div className="flex flex-row items-center gap-10 mb-5">
        <div className="flex-[1] h-[1px] bg-[#178BE4]" />
        <span className='text-[#178BE4] font-bold'>Terms & Conditions</span>
        <div className="flex-[1] h-[1px] bg-[#178BE4]" />
      </div>
      <p className='h-[250px] overflow-auto custom-scrollbar pr-2 mb-2'>
        This agreement (“agreement”) is made today, between: CommuteSaver Inc, a Delaware corporation (“Company”) and the Customer (“Customer”). Company and Customer may be referred to in this agreement individually as a “party” and collectively as “parties”.

        <p>1.	Software. Under this agreement Company will use commercially reasonable efforts to provide Customer a limited license to use CommuteSaver EV, a API and reporting solution for EV owners to gain benefit from the LCFS credit system  (“Software”) for the purpose of determining the usability, desirability, functionality, and compatibility of Software with Customer’s applications and operations environment (“Pilot”).</p>

        <p>2.	Technical support. Company will provide Customer with reasonable technical support services in accordance with Company’s standard practice.</p>

        <p>3.	Duration of usage. Use of the software will start today  and continue for a period of 1 year (“Period”). </p>

        <p>4.	Fees. Customer will pay Company a total of 30% admin fee of the total credits generated. </p>

        <p>5.	Restrictions and responsibilities. Customer will not, directly or indirectly: </p>
        <p>(a)	reverse engineer, decompile, disassemble or otherwise attempt to discover the source code, object code or underlying structure, ideas, know-how or algorithms relevant to Software;</p>
        <p>(b)	modify, translate, or create derivative works based on Software;</p>
        <p>(c)	use Software for timesharing or service bureau purposes or otherwise for the benefit of a third; or remove any proprietary notices or labels.</p>

        <p>6.	Compliance with laws. Customer represents, covenants, and warrants that Customer will use Services only in compliance with Company’s standard published policies then in effect and all applicable laws and regulations. Although Company has no obligation to monitor Customer’s use of Services, Company may do so and may prohibit any use of Services it believes may be (or alleged to be) in violation of the foregoing.</p>

        <p>7.	Customer Data. Company will own all right, title and interest in and to Customer Data for the intended purpose.</p>

        <p>Company will own and retain all right, title and interest in and to:</p>
        <p>(a)	Software, all improvements, enhancements or modifications thereto;</p>
        <p>(b)	any software, applications, inventions or other technology developed; </p>
        <p>(c)	all intellectual property rights related to any of the foregoing.</p>

        <p>8.	Warranty and disclaimer. Company will use reasonable efforts consistent with prevailing industry standards to maintain Software in a manner which minimizes errors and interruptions in functioning of Software. Software may be temporarily unavailable for scheduled maintenance or for unscheduled emergency maintenance, either by Company or by third-party providers, or because of other causes beyond Company’s reasonable control, but Company will use reasonable efforts to provide advance notice in writing or by e-mail of any scheduled service disruption. However, company does not warrant that functioning of software will be uninterrupted or error free; nor does it make any warranty as to the results that may be obtained from use of software. Except as expressly set forth in this section, software is provided “as is” and company disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose and non-infringement.</p>

        <p>9.	Limitation of liability. Notwithstanding anything to contrary, except for bodily injury of a person, company and its suppliers (including but not limited to all equipment and technology suppliers), officers, affiliates, representatives, contractors and employees will not be responsible or liable with respect to any subject matter of this agreement or terms and conditions related thereto under any contract, negligence, strict liability or other theory:</p>
        <p>(a)	for error or interruption of use or for loss or inaccuracy or corruption of data or cost of procurement of substitute goods, services or technology or loss of business;</p>
        <p>(b)	or any indirect, exemplary, incidental, special or consequential damages;</p>
        <p> (c)	for any matter beyond company’s reasonable control; or</p>
        <p> (d)	for any amounts that, together with amounts associated with all other claims, exceed the fees paid by customer to company for the software under this agreement in the 12 months prior to the act that gave rise to the liability, </p>
        <p> in each case, whether company has been advised of the possibility of such damages.</p>

        <p> 10.	Term. This agreement begins today and, unless terminated earlier under this agreement, continues until the end of the Period. Upon expiration or termination of this agreement, Customer will no longer have access to or the right to use Software, unless the parties enter into an agreement for the continued use of Software.</p>

        <p> 11.	Independent contractors. Customer and Company are independent contractors in all matters relating to this agreement, and this agreement will not be construed to create a partnership, joint venture, agency, employment, or any other relationship between Customer and Company. </p>

        <p> 12.	Governing law. This agreement is governed by the laws of the State of Delaware, without giving effect to principles of conflicts of law.</p>

        <p> 13.	Final provisions.</p>
        <p> (a)	This agreement constitutes the entire agreement between the parties with respect to its subject matter and supersedes all prior agreements between the parties regarding the same subject matter.</p>
        <p> (b)	This agreement can be modified only by a written amendment signed by the parties. </p>
        <p> (c)	Failure to enforce any provisions of this agreement will not constitute a waiver.</p>
        <p> (d)	If any provision is unenforceable, the other provisions will remain effective. </p>
        <p> (e)	Parties may execute this agreement by electronic signatures or by facsimile in counterparts, which taken together will constitute one instrument.</p>
        <p> (f)	Neither party may assign or transfer this agreement without the prior written consent of the other party.</p>
        <p> (g)	Any notice under this agreement must be in writing and delivered personally or by overnight courier or sent by email.</p>
        <p> (h)	The section headings of this agreement are for convenience only and have no value for interpretation of agreement.</p>

        <p> 14.	Survival. The following provisions survive termination or expiration of this agreement: 17 (Limitations of liabilities), 20 (Governing law) and 21 (Final provisions). </p>

      </p>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel value="agree" control={<Radio size="small" />} label="I agree to terms and condition" />
          <FormControlLabel value="disagree" control={<Radio size="small" />} label="I don't agree to terms and condition" />
        </RadioGroup>
      </FormControl>
      <div className='flex flex-row gap-5 items-start'>
        <button className='bg-[#EEEEEE] text-[#000] p-2 rounded font-bold w-full' onClick={goBackStep} type={'button'}>Back</button>
        <button disabled={value === 'disagree'} onClick={onClick} className={` ${value == 'agree' ? 'bg-[#178BE4]' : 'bg-[#C6E2F8]'} p-2 rounded text-white font-bold w-full`} type="button">Next</button>
      </div>

    </section>
  )
}
