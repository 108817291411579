import React, { useState } from 'react'
import logo from '../assets/images/white-logo.png'
import contactImg from '../assets/images/contact-img.png'
import contactVid from '../assets/videos/Contact-Vid.mp4'
import axios from 'axios'
import {functions} from '../config/firebase'
import { httpsCallable } from "firebase/functions";


const fields = [
    { title: 'Company Name', name: 'company', type: 'text', placeholder: 'Company Name', required: true },
    { title: 'Email', name: 'email', type: 'email', placeholder: 'Email', required: true },
    { title: 'Phone Number (optional)', name: 'phone', type: 'tel', placeholder: 'Phone No.', required: false },
]

export default function Contact() {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        phone: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log(formData);

        // ============================== //
        //          Form Setup
        // ============================== //
        //
        try {
            const response = await axios.post(
                'https://commutesaver.net/api/api/public/contact-us/',
                {...formData, ...{company: 'CommuteSaverEV- ' + formData.company}}
            );

            // Check the response status
            if (response.status === 200) {
                // Handle successful form submission on the frontend
                console.log('Form submitted successfully');
            } else {
                // Handle errors from the backend
                console.error('Form submission failed');
            }
        } catch (error) {
            console.error('An error occurred while submitting the form', error);
        }
    };

    return (
        <section id='contact' className='grid flex-col-reverse sm:flex-row grid-cols-1 sm:grid-cols-2'>
            <div className="h-[auto] sm:h-full sm:order-last">
                <video className='object-cover sm:h-full w-full' muted autoPlay loop>
                    <source src={contactVid} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                {/* <img className='object-cover h-full' src={contactImg} alt="contact img" /> */}
            </div>

            <article className='px-[24px] py-[60px] bg-[#199CFF] text-white'>
                <h2 className='text-center text-2xl mb-10'>Contact us for more info</h2>
                <form className='mx-auto max-w-[450px]' onSubmit={handleSubmit}>
                    <article className='flex flex-row gap-5 justify-between'>
                        <div>
                            <label className='ml-1 text-[18px] text-bold' htmlFor='firstName'>
                                First Name
                            </label>{' '}
                            <br />
                            <input
                                className={`bg-white p-3 border-1 rounded-[10px] w-full mb-5 text-black outline-0`}
                                id='firstName'
                                type='text'
                                name='firstName'
                                placeholder='First Name'
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label className='ml-1 text-[18px] text-bold' htmlFor='lastName'>
                                Last Name
                            </label>{' '}
                            <br />
                            <input
                                className={`bg-white p-3 border-1 rounded-[10px] w-full mb-5 text-black outline-0`}
                                id='lastName'
                                type='text'
                                name='lastName'
                                placeholder='Last Name'
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </article>
                    {fields.map((f, i) => (
                        <div key={i}>
                            <label className='ml-1 text-[18px] text-bold' htmlFor={f.name}>
                                {f.title}
                            </label>{' '}
                            <br />
                            <input
                                className={`bg-white p-3 border-1 rounded-[10px] w-full mb-5 text-black outline-0`}
                                id={f.name}
                                type={f.type}
                                name={f.name}
                                placeholder={f.placeholder}
                                value={formData[f.name]}
                                onChange={handleChange}
                                required={f.required}
                            />
                        </div>
                    ))}
                    <div className='text-center'>
                        <button className='bg-[#656565] border-0 px-[24px] py-[12px] rounded-[15px] mx-auto' type='submit'>
                            Send Now
                        </button>
                    </div>
                </form>
                <article className='max-w-[450px] mx-auto mt-[100px]'>
                    <a href='/'>
                        <img src={logo} alt="logo" />
                    </a>
                    <p className='mt-5'>
                        CommuteSaver EV Inc.<br/>
                        2150 Shattuck Ave <br />
                        Berkeley, CA 94704
                    </p>
                    <p className='mt-5'>
                        ✉️ hello@commutesaverev.com
                    </p>
                    <p className='mt-5'>
                        © 2024 CommuteSaver EV. all rights reserved
                    </p>
                </article>
            </article>

        </section>
    )
}
