// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
// import { getAuth } from "firebase/auth";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// const firebaseConfig = {
//     apiKey: "AIzaSyBhW0C9J3woB1IGJSilE62_56yz-eUzIdI",
//     authDomain: "test-auth-and-firestore-3b75a.firebaseapp.com",
//     projectId: "test-auth-and-firestore-3b75a",
//     storageBucket: "test-auth-and-firestore-3b75a.appspot.com",
//     messagingSenderId: "221248728297",
//     appId: "1:221248728297:web:54002c5a031a82995e9478"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// export const auth = getAuth(app);

// // Initialize Cloud Firestore and get a reference to the service
// export const db = getFirestore(app);
// export default app




// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFunctions } from 'firebase/functions';
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCUh4yxr7cPv_QgmgGr3H2V6jZNwcatBxc",
    authDomain: "commutesaverev.firebaseapp.com",
    projectId: "commutesaverev",
    storageBucket: "commutesaverev.appspot.com",
    messagingSenderId: "644753849956",
    appId: "1:644753849956:web:c0f476787a8a5a17476971"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
export const functions = getFunctions(app);
export default app