import React from 'react'

export default function Step3({ onClick }) {
  return (
    <section className='flex flex-col gap-5 items-center justify-center text-[#199CFF] h-full'>
      <span className='text-[20px]'>
        Your Account
        <br />
        Created Successfully
      </span>
      <span className='text-[30px] font-bold'>Congratulations!</span>
      <button onClick={onClick} className='bg-[#178BE4] p-2 rounded text-white font-bold w-full' type="button">Login</button>
    </section>
  )
}
