import React from 'react'

export default function Field({ id, type, label, placeholder, value, onChange, msg, required = true }) {
    return (
        <div className="flex flex-col items-start">
            <label className='text-white md:text-[#178BE4] text-[16px]' htmlFor={id}>{label}</label>
            <input className='border-2 rounded p-2 w-[100%] bg-[white]' id={id} name={id} type={type} placeholder={placeholder ? placeholder : label} value={value} onChange={onChange} required={required} />
            {msg && <span className='text-[red] text-[13px] text-start'>{msg}</span>}
        </div>
    )
}
